import { fetchWithFailover } from 'default-client/DefaultClient';

export const logout = () => {
  const url = '/auth/logout';
  return fetchWithFailover(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
    },
  });
};

export const logoutInOldApp = () => {
  const url = '/logout.html?reason=loginPageLoaded';
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
  });
};
