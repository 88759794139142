import { useState } from 'react';
import { Base64 } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { ga } from 'mypages-library';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { parseError } from 'common/errors/errorUtils';
import { getLocale } from 'common/languageUtil';
import { authenticateWildfly } from 'common/wildflyAuthentication';
import { navigateTo } from 'common/navigationUtil';
import { simpleSentryLog } from 'common/utilities/simpleSentryLog';

export const useAuthenticate = () => {
  const [errorBody, setErrorBody] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMultiFactorAuthentication, setIsMultiFactorAuthentication] = useState(false);
  const { i18n } = useTranslation();
  const getLanguage = () => i18n.language || 'se';
  const loginUrl = '/auth/login';

  const track = eventLabel => {
    ga.trackEvent({
      eventCategory: 'site login',
      eventAction: 'site login',
      eventLabel,
    });
  };

  const handleError = e => {
    setErrorBody({ errorCode: e.errorCode, message: e.message, verified: e.verified });
    setIsLoading(false);
    track('fail');
  };

  const handleSingleSignOnError = e => {
    setErrorBody({ errorCode: e.errorCode, message: e.message, verified: e.verified });
    setIsLoading(false);
    track('Single sign on fail');
  };

  const handleSentryLogging = e => {
    simpleSentryLog('e-commerce redirect', { e }, { giid: 'unknown' });
  };

  const handleAuthenticateResponse = async response => {
    if (response.status !== 200) {
      const errorCodeReceived = await parseError(response);
      const error = new Error(errorCodeReceived.message);
      error.verified = errorCodeReceived.verified;
      error.errorCode = errorCodeReceived.errorCode;

      throw error;
    }
    return response.json();
  };

  const routeLoginFlow = (data, email, setSuccessfulLogin) => {
    track('success');
    if (data.stepUpToken != null) {
      setIsMultiFactorAuthentication(true);
      ga.trackEvent({
        eventCategory: 'site login',
        eventAction: 'mfa started',
      });
      return Promise.resolve();
    }
    return Promise.resolve()
      .then(() => authenticateWildfly(data, email, getLocale(getLanguage()), setErrorBody, setIsLoading))
      .then(() => {
        navigateTo(getLanguage());
        setSuccessfulLogin(true);
      });
  };

  const getHeaders = (email, password, isOperator) => {
    if (!isOperator) {
      return {
        authorization: `Basic ${Base64.encode(`${email}:${password}`)}`,
        accept: 'application/json',
        APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
      };
    }

    const [username, impersonationToken] = email.split('*');
    return {
      authorization: `Basic ${Base64.encode(`${username}:${password}`)}`,
      accept: 'application/json',
      APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
      impersonationToken,
    };
  };

  const authenticate = (email, password, setSuccessfulLogin, redirectToEcommerce) => {
    if (errorBody !== null) {
      setErrorBody(null);
    }
    const isOperator = email.includes('*') && !email.includes('@');
    const headers = getHeaders(email, password, isOperator);

    setIsLoading(true);
    return fetchWithFailover(loginUrl, {
      method: 'POST',
      credentials: 'include',
      headers,
    })
      .then(handleAuthenticateResponse)
      .then(response => routeLoginFlow(response, email, setSuccessfulLogin))
      .catch(e => {
        handleError(e);
        if (redirectToEcommerce) {
          handleSentryLogging();
        }
      });
  };
  const authenticateSingleSignOn = email => {
    if (errorBody !== null) {
      setErrorBody(null);
    }
    setIsLoading(true);
    fetchWithFailover(loginUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
      },
    })
      .then(handleAuthenticateResponse)
      .then(response => routeLoginFlow(response, email, () => null))
      .catch(e => {
        handleSingleSignOnError(e);
      })
      .finally(() => setIsLoading(false));
  };
  return { isMultiFactorAuthentication, isLoading, errorBody, authenticate, authenticateSingleSignOn };
};
