import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { popEcommerceLogoutUrl } from 'mypages-library/dist/services/ecomLogout/ecomLogout';
import { goToUrl } from 'mypages-library/dist/services/goToUrl/goToUrl';
import { logout, logoutInOldApp } from 'api/authentication';
import { getQueryParam, isLoginToMypages } from 'common/urlParser';
import { deleteChatHistory } from 'common/leadGenUtil';

export const initStates = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
};

const COOKIE_ATTRIBUTES = {
  path: '/',
  domain: '.verisure.com',
};

export const clearSessionStorageKeepAppSettings = () => {
  const runningInApp = sessionStorage.getItem('runningInApp');
  sessionStorage.clear();
  if (runningInApp) {
    sessionStorage.setItem('runningInApp', runningInApp);
  }
};

const cleanUpLocalStorage = () => {
  deleteChatHistory();
};

export const useInitialize = () => {
  const [state, setState] = useState(initStates.IN_PROGRESS);
  const [inapp] = useState(getQueryParam('inapp', window.location.search) === 'true');
  const [giid] = useState(getQueryParam('installation', window.location.search));
  const [deepLink] = useState(getQueryParam('deeplink', window.location.search) === 'true');

  if (giid) {
    Cookies.set('giid', giid, COOKIE_ATTRIBUTES);
    sessionStorage.setItem('app.settings.selected', JSON.stringify({ installationId: giid }));
  }
  if (deepLink) {
    sessionStorage.setItem('deepLink', true);
  }
  if (inapp) {
    sessionStorage.setItem('runningInApp', true);
  }
  useEffect(() => {
    const initialize = async () => {
      try {
        if (isLoginToMypages()) {
          await logout();
          await logoutInOldApp();
        }
        const ecomLogoutUrl = popEcommerceLogoutUrl();
        clearSessionStorageKeepAppSettings();
        cleanUpLocalStorage();
        Cookies.remove('username', COOKIE_ATTRIBUTES);
        Cookies.remove('operatorId', COOKIE_ATTRIBUTES);
        if (ecomLogoutUrl) {
          goToUrl(ecomLogoutUrl);
        }
      } catch (error) {
        // Better luck next time
      }
      setState(initStates.DONE);
    };
    initialize();
  }, []);

  return { state, inapp };
};
