import { apiEndpoint } from 'mypages-library';

export const getQueryParam = (key, search) => {
  if (!search) {
    return null;
  }
  const query = new URLSearchParams(search.substring(1));
  return query.get(key) === undefined ? null : query.get(key);
};

export const getRedirectValue = () =>
  getQueryParam('redirect', window.location.search) === null
    ? null
    : window.atob(getQueryParam('redirect', window.location.search));

const getOauthBaseURL = () => {
  const environment = apiEndpoint.getCurrentEnvironment(window.location.hostname);
  const testEnvironmentHostname = 'galactica-oauth.dev.verisure.com';

  if (environment === 'test') {
    return testEnvironmentHostname;
  }

  return window.location.hostname.replace('www.', '');
};

export const getOAuthRedirectValue = () => {
  const oauthParam = getQueryParam('oauth', window.location.search);
  if (oauthParam === null) {
    return null;
  }

  return `https://${getOauthBaseURL()}/auth/login/?login_challenge=${oauthParam}&skipRedirect=true`;
};

export const isRedirectToWebshop = () =>
  getRedirectValue() === null
    ? false
    : getRedirectValue().search('webshop.html') > 0 || getRedirectValue().search('webshopcheckout.html') > 0;

const isOAuthRedirect = () => getQueryParam('oauth', window.location.search) !== null;

export const isLoginToMypages = () => {
  if (isOAuthRedirect()) {
    return false;
  }
  return true;
};
