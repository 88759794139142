import React, { useState } from 'react';
import { Button, Checkbox, appConstants, validation } from 'mypages-library';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Input } from 'components/Input/Input';
import { t } from 'i18n/translate';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import InternationalPhonePicker from 'components/InternationalPhonePicker/InternationalPhonePicker';
import PasswordInput from 'components/Input/PasswordInput';
import CountryPicker from 'components/CreateAccount/CountrySelector/CountrySelector';
import InputValidation from '../Input/InputValidation';
import {
  isPasswordValid,
  passwordAndRepeatMatch,
  getPasswordRequirements,
  getCurrentPasswordErrors,
} from '../Input/passwordValidationUtil';
import styles from './CreateAccount.module.scss';

export const localeToCountryStringMapping = {
  de_de: 'Deutschland',
  da_dk: 'Danmark',
  fi_fi: 'Suomi',
  fr_fr: 'France',
  fr_be: 'Belgique',
  nl_nl: 'Nederland',
  nl_be: 'België',
  no_no: 'Norge',
  sv_se: 'Sverige',
  en_gb: 'United Kingdom',
} as const;

const CreateAccount: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [agree, setAgree] = useState(false);

  const countryCookie: string | undefined = Cookies.get('country');
  const languageCookie: string | undefined = Cookies.get('language');
  const defaultCountry = `${languageCookie?.toLowerCase()}_${countryCookie?.toLowerCase()}`;
  const countryName = localeToCountryStringMapping[defaultCountry as keyof typeof localeToCountryStringMapping];
  const [country, setCountry] = useState<string>(countryName);
  const [loading, setLoading] = useState(false);
  const { isNameValid, isPhoneNumberValid, isEmailValid } = validation;
  const { NAME_MAX_LENGTH, NAME_MIN_LENGTH } = appConstants;
  const firstNameError = firstName?.length > 0 && !isNameValid(firstName);
  const firstNameErrorMsg = firstNameError ? t('MPL_CREATE_ACCOUNT_NAME_VALIDATION_ERROR') : '';
  const lastNameError = lastName?.length > 0 && !isNameValid(lastName);
  const lastNameErrorMsg = lastNameError ? t('MPL_CREATE_ACCOUNT_NAME_VALIDATION_ERROR') : '';
  const phoneError = phoneNumber?.length > 0 && !isPhoneNumberValid(phoneNumber);
  const emailError = email?.length > 0 && !isEmailValid(email);
  const emailErrorMsg = emailError ? t('MPL_CREATE_ACCOUNT_INVALID_EMAIL') : '';
  const passwordError = password?.length > 0 && !isPasswordValid(password);
  const repeatPasswordError = repeatPassword?.length > 0 && !passwordAndRepeatMatch(password, repeatPassword);
  const repeatPasswordErrorMsg = repeatPasswordError ? t('MPL_PASSWORD_VALIDATION_PASSWORDS_NOT_MATCHING') : '';

  const validForm =
    firstName?.length > 0 &&
    !firstNameError &&
    lastName?.length > 0 &&
    !lastNameError &&
    phoneNumber?.length > 0 &&
    !phoneError &&
    email?.length > 0 &&
    !emailError &&
    password?.length > 0 &&
    !passwordError &&
    repeatPassword?.length > 0 &&
    !repeatPasswordError;

  const handleSubmit = () => {
    setLoading(true);
  };

  const onSelectionFieldChange = (selected: string) => {
    if (agree) {
      setAgree(false);
    }
    setCountry(selected);
  };

  return (
    <div className={styles['login-wrapper']}>
      <main className={styles['login-content']}>
        <SubsidiaryAppLogo />
        <div className={styles.inputContainer}>
          <Input
            onChange={value => setFirstName(value)}
            placeholder={t('MPL_CREATE_ACCOUNT_FIRSTNAME')}
            value={firstName}
            dataTestId="firstNameInput"
            disabled={loading}
            maxLength={NAME_MAX_LENGTH}
            minLength={NAME_MIN_LENGTH}
            hasError={firstNameError}
            errorMessage={firstNameErrorMsg}
          />
          <Input
            onChange={value => setLastName(value)}
            placeholder={t('MPL_CREATE_ACCOUNT_LASTNAME')}
            value={lastName}
            dataTestId="lastNameInput"
            disabled={loading}
            maxLength={NAME_MAX_LENGTH}
            minLength={NAME_MIN_LENGTH}
            hasError={lastNameError}
            errorMessage={lastNameErrorMsg}
          />
          <InternationalPhonePicker
            presetLanguage={language}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneError={phoneError}
          />

          <Input
            type="text"
            placeholder={t('MPL_LOGIN_PLACEHOLDER_EMAIL')}
            onChange={value => {
              setEmail(value.trim());
            }}
            value={email}
            maxLength={255}
            className={styles['login-field']}
            dataTestId="mypages-login-email"
            autoComplete="username"
            minLengthErrorText={t('MPL_NOT_ENOUGH_CHARACTERS_ERROR')}
            mandatoryInfoText={t('MPL_ETC_MANDATORY_INPUT_FIELD')}
            hasError={emailError}
            errorMessage={emailErrorMsg}
          />
          <InputValidation
            inputToValidate={password}
            requirements={getPasswordRequirements(password)}
            errorList={getCurrentPasswordErrors(password)}
          >
            <PasswordInput
              placeholder={t('MPL_LOGIN_PLACEHOLDER_PASSWORD')}
              onChange={(value: string) => setPassword(value)}
              value={password}
              maxLength={30}
              className={styles['login-field']}
              dataTestId="mypages-login-password"
              autoComplete="current-password"
              hasError={passwordError}
            />
          </InputValidation>
          <PasswordInput
            placeholder={t('MPL_LOGIN_PLACEHOLDER_REPEAT_PASSWORD')}
            onChange={(value: string) => setRepeatPassword(value)}
            value={repeatPassword}
            maxLength={30}
            className={styles['login-field']}
            dataTestId="mypages-login-password-repeat"
            autoComplete="current-password"
            hasError={repeatPasswordError}
            errorMessage={repeatPasswordErrorMsg}
          />
          <CountryPicker
            entries={Object.values(localeToCountryStringMapping)}
            onSelectionFieldChange={onSelectionFieldChange}
            defaultValue={country}
            disabled={false}
          />
          <div className={styles.agreement}>
            <Checkbox dataTestId="placement-toggle-agreement" isChecked={agree} onToggle={() => setAgree(!agree)} />
            <p className={styles.txt}> {t(`MPL_TERMS_AND_CONDITIONS`)} </p>
          </div>
        </div>
        <Button
          text={t('MPL_SAVE')}
          className={styles['login-button']}
          red
          onClick={handleSubmit}
          dataTestId="mypages-create-submit"
          disabled={!validForm}
        />
      </main>
    </div>
  );
};

export default CreateAccount;
