import { getQueryParam } from 'common/urlParser';

export const mapLang = language => {
  if (!language) {
    return false;
  }
  switch (language) {
    case 'dk':
    case 'de':
    case 'uk':
    case 'fr':
    case 'frbe':
    case 'nlbe':
    case 'nl':
    case 'no':
    case 'fi':
    case 'se':
      return language;
    default:
      return 'se';
  }
};
export default {
  name: 'getLangFromQuery',

  lookup() {
    return mapLang(getQueryParam('lang', window.location.search));
  },
};
