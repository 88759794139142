import React, { useRef } from 'react';
import classNames from 'classnames';
import { Icon, useClickOutsideElement } from 'mypages-library';
import DropDownBox from '../DropDownBox/DropDownBox';
import styles from './PhoneSelectButton.module.scss';

interface MenuController {
  menuExpanded: boolean;
  setMenuExpanded: (expanded: boolean) => void;
}

interface PhoneSelectButtonProps {
  label: string;
  children?: React.ReactNode;
  menuController: MenuController;
}

const PhoneSelectButton: React.FC<PhoneSelectButtonProps> = ({ label, children, menuController }) => {
  const { menuExpanded, setMenuExpanded } = menuController;
  const refNode = useRef<HTMLDivElement | null>(null);
  useClickOutsideElement(refNode, () => setMenuExpanded(false));
  const buttonArrow = menuExpanded ? 'icon-arrow-up' : 'icon-arrow-down';
  return (
    <div className={classNames(styles.dropDownButton, menuExpanded && styles.expanded)} ref={refNode}>
      <button
        type="button"
        onClick={() => setMenuExpanded(!menuExpanded)}
        className={classNames(!children && styles.disabled, styles.phoneSelectButton)}
        id="phoneSelectButton"
      >
        {children && <Icon icon={buttonArrow} className={styles.buttonArrow} size="small" />}
        {label && <span className={styles.buttonLabel}>{label}</span>}
      </button>
      <DropDownBox className={menuExpanded ? styles.maxheight : ''} expanded={menuExpanded}>
        {children}
      </DropDownBox>
    </div>
  );
};

export default PhoneSelectButton;
