import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Icon, PopOver, SelectionField, useClickOutsideElement } from 'mypages-library';
import { t } from 'i18n/translate';

import styles from './CountrySelector.module.scss';

interface CountrySelectorProps {
  defaultValue: string;
  disabled: boolean;
  onSelectionFieldChange: (value: string) => void;
  entries: string[];
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
  defaultValue,
  disabled,
  onSelectionFieldChange,
  entries,
}) => {
  const [infoTextVisible, setInfoTextVisible] = useState(false);
  const onClickInfo = () => {
    setInfoTextVisible(true);
    setTimeout(() => setInfoTextVisible(false), 10000);
  };
  const popOver = useRef(null);
  const closePreview = () => {
    setInfoTextVisible(false);
  };
  useClickOutsideElement(popOver, closePreview);

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <p className={styles.title}>{t('MPL_CREATE_ACCOUNT_COUNTRY_SELECTOR_TITLE')}</p>
        <div className={styles.popoverContainer}>
          <Icon icon="icon-help-contextual" size="small" className={styles.icon} onClick={onClickInfo} />
          {infoTextVisible && (
            <PopOver
              visible={infoTextVisible}
              className={classNames(styles.popover, infoTextVisible && styles.popoverVisible)}
            >
              <p className={styles.infoText}>{t('MPL_CREATE_ACCOUNT_COUNTRY_SELECTOR_INFORMATION')}</p>
            </PopOver>
          )}
        </div>
      </div>
      <SelectionField
        name="country-picker"
        onChange={onSelectionFieldChange}
        defaultValue={defaultValue}
        disabled={disabled}
        dataTestId="countrySelector"
      >
        {entries.map(option => (
          <option key={option} value={option} id={`countrySelectorOption${option}`}>
            {option}
          </option>
        ))}
      </SelectionField>
    </div>
  );
};

export default CountrySelector;
