import { t } from 'i18n/translate';
import { logout } from 'api/authentication';
import { getQueryParam, isLoginToMypages } from 'common/urlParser';

const baseURL = () => (window?.location?.hostname === 'localhost' ? 'http://localhost:4000' : '');

const handleAuthenticateWildflyResponse = async (response, setError, setIsLoading) => {
  let data = null;
  let errorMessage;
  try {
    data = await response.json();
    errorMessage = data.status === 'error' ? data.message : null;
  } catch (e) {
    // Not a json response, show generic error message
    errorMessage = t('MPL_ETC_ERROR_OCCURED_TRY_AGAIN');
  }
  if (errorMessage) {
    try {
      // Ending up here means that /auth/login was successful but authenticate wildfly failed.
      // Call /auth/logout to invalidate the cookies we got from /auth/login.
      await logout();
    } catch (e) {
      // Not much we can do
    }
    setIsLoading(false);
    throw new Error(errorMessage);
  }
  setError(null);
  return data;
};

const setInstallation = () => {
  const giid = getQueryParam('installation', window.location.search);
  if (giid && giid !== 'null') {
    return fetch(`${baseURL()}/setinstallation?giid=${giid}`, {
      method: 'GET',
      credentials: 'include',
    });
  }
  return Promise.resolve();
};

export const authenticateWildfly = ({ refreshToken }, email, locale, setError, setIsLoading) => {
  if (isLoginToMypages()) {
    const formData = new URLSearchParams();
    formData.append('j_username', email);
    return fetch(`${baseURL()}/j_spring_security_check?locale=${locale}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-vs-refresh': refreshToken,
      },
      body: formData,
    })
      .then(response => handleAuthenticateWildflyResponse(response, setError, setIsLoading))
      .then(setInstallation);
  }
  return null;
};
