import { ga } from 'mypages-library';
import { getRedirectValue, getQueryParam, getOAuthRedirectValue } from './urlParser';

export const navigationUrl = (language: string) => {
  const redirectUrl = getRedirectValue();
  const giid = getQueryParam('installation', window.location.search);

  if (giid) {
    sessionStorage.setItem('app.settings.selected', JSON.stringify({ installationId: giid }));
  }

  const oauthUrl = getOAuthRedirectValue();

  if (oauthUrl !== null) {
    return oauthUrl;
  }

  let url = `/${language}/status`;
  if (redirectUrl) {
    url = redirectUrl;
    if (url.startsWith('/')) {
      url = `/${language}${url}`;
    } else {
      url = `/${url}`;
    }
  }
  return url;
};

export const navigateTo = (language: string) => {
  ga.trackEvent({
    eventCategory: 'open',
    eventAction: 'mypages',
  });
  window.location.replace(navigationUrl(language));
};
